<template>
   <section class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>营销云</el-breadcrumb-item>
            <el-breadcrumb-item>营销模板</el-breadcrumb-item>
            <el-breadcrumb-item>{{pagetype == 'add' ? '新增模板' : pagetype == 'edit' ? '修改模板' : '查看模板'}}</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-page-header @back="$router.push('/marketing_template')" :content="pagetype == 'add' ? '新增模板' : pagetype == 'edit' ? '修改模板' : '查看模板'"></el-page-header>
      <el-row class="pre-sale-main">
         <el-row class="right-form" :gutter="12">
            <el-form ref="formData" :model="formData" label-width="110px" @submit.native.prevent style="position:relative">
                <!-- <el-form-item style="position:absolute;top:-10px;left:600px">
                  <el-button type="primary" @click="submit()" v-if="!disabled">保存</el-button>
               </el-form-item> -->
               <el-form-item label="模板名称" style="width:400px;margin-top:40px" required>
                  <el-input v-model="formData.name" placeholder="请输入名称" :disabled="disabled"></el-input>
               </el-form-item>

               <el-form-item label="选择标签" style="width:500px" required>
                 <el-col :span="18">
                  <el-input :placeholder="formData.tagId == '' ? '未选择' : '已选择'" disabled></el-input>
                 </el-col>
                <el-col :span="4">
                   <el-button @click="dialogTagVisible = true" type="primary" :disabled="disabled">选择标签</el-button>
                </el-col>
               </el-form-item>

                <el-form-item label="人群设定" style="width:500px" required>
                 <el-col :span="18">
                  <el-input :placeholder="formData.propStr == '' && formData.touchStr == '' ? '未选择' : '已选择'" disabled></el-input>
                 </el-col>
                <el-col :span="4">
                   <el-button type="primary" @click="visibleCondition = true" :disabled="disabled">条件设置</el-button>
                </el-col>
               </el-form-item>

               <el-form-item label="内容类型" style="width:800px">
                  <el-radio-group v-model="formData.contentType" :disabled="disabled">
                        <el-radio label="TEXT">文字</el-radio>
                        <el-radio label="IMAGE">图片</el-radio>
                        <el-radio label="COMPOSITE">综合</el-radio>
                  </el-radio-group>
                   <el-row>
                     <el-col :span="10" v-if="formData.contentType != 'IMAGE'">
                        <el-input type="textarea" v-model="formData.content" rows="8" placeholder="请输入文案" :disabled="disabled"></el-input>
                     </el-col>
                     <el-col :span="12" :offset="1" v-if="formData.contentType != 'TEXT'">
                        <el-row class="flex-pic" >
                           <el-button type="primary" @click="visibleImage = true" :disabled="disabled" style="margin-right:20px">上传图片</el-button>
                           <img width="148" height="148" class="old-pic" :src="formData.image" alt="">
                            <!-- 图片上传 -->
                           <Cropper :visible="visibleImage" @setImageInfo="getImageInfo" @closeUploadImg="closeUploadImg" :filmSize="filmSize"></Cropper>
                        </el-row>
                     </el-col>
                   </el-row>
               </el-form-item>
               <el-form-item label="支持类型" v-for="(item,index) in supportTypeSelect" :key="index">
                  <el-row style="width:800px">
                     <span class="type-text" v-for="content in item.content" :key="content">{{content}}</span>
                  </el-row>
               </el-form-item>

               <el-form-item label="模板状态">
                  <el-radio-group v-model="formData.status" :disabled="disabled">
                        <el-radio label="YES">开启</el-radio>
                        <el-radio label="NO">关闭</el-radio>
                  </el-radio-group>
               </el-form-item>

               <el-form-item label="模板说明" style="width:800px" required>
                  <el-input v-model="formData.description" placeholder="填写说明" :disabled="disabled"></el-input>
               </el-form-item>
               <el-form-item style="margin-top:30px;margin-left:-70px">
                  <el-button type="primary" @click="submit()" v-if="!disabled">保存</el-button>
               </el-form-item>
            </el-form>
         </el-row>
      </el-row>

      <!-- 标签管理 -->
     <el-dialog title="选择标签" :visible.sync="dialogTagVisible" width="600px">
      <el-table :data="tagData">
         <el-table-column label="选择" width="100">
            <template slot-scope="scope">
               <el-checkbox v-model="scope.row.checked"></el-checkbox>
            </template>
         </el-table-column>
         <el-table-column prop="name" label="标签名称"></el-table-column>
      </el-table>
       <span slot="footer" class="dialog-footer">
         <el-button @click="dialogTagVisible = false">取 消</el-button>
         <el-button type="primary" @click="tagChecked()">确 定</el-button>
      </span>
     </el-dialog>

       <!-- 条件管理 -->
      <el-dialog title="条件管理" :visible.sync="visibleCondition" :close-on-click-modal="false" width="800px">
         <condition @condition="condition" @close="visibleCondition = false" :all="true"/>
      </el-dialog>
   </section>
</template>

<script>
import { urlObj } from "@/api/interface";
import Cropper from '@/components/local/Cropper'
import { mapState } from 'vuex'
import condition from '@/components/local/conditionMan'
import { getTagList, saveTemp } from '@/api/smtech/marketingTemplate.js'
export default {
   data() {
      return {
         pagetype: '',
         disabled: false,
         hotelId: '',
         dialogTagVisible: false, //选择标签弹窗
         postershow: false,//海报重新渲染
         visibleCondition: false, //条件设置弹窗
         // imgdialogVisible: false, //图片弹窗
         visibleImage: false,  //图片弹窗
         isEcho: true,
         imglist:[],
         formData: {
            contentType: 'TEXT',// 内容类型
            content: '',    //模板内容
            name: "",//标题
            image: '',//海报
            hotelId: '',
            status: 'YES', //模板状态
            propStr: '',  //用户属性
            propStrName: '',
            touchStr: '', //触发条件
            touchStrName: '',
            supportTypes: [], //支持类型
            tagId: '', //标签id
            description: '',  //描述说明
         },
         tagData: [],
         supportType: [
            {
               contentType: 'TEXT',
               content: {DOUYIN_PLATFORM:"抖音", SMS:"短信",WECHAT_OFFICIAL:"公众号"}
            },
            {
               contentType: 'IMAGE',
               content: {DOUYIN_PLATFORM:"抖音", WECHAT_APPLET:"小程序", WECHAT_OFFICIAL:"公众号",}
            },
            {
               contentType: 'COMPOSITE',
               content: {DOUYIN_PLATFORM:"抖音", SMS:"短信", WECHAT_APPLET:"小程序", WECHAT_OFFICIAL:"公众号",}
            },
         ],

         gender: {
            MAN: '男性',
            WOMAN: '女性',
            SECRET: '保密'
         },

         operator: {
            GT: '>',
            EQ: '=',
            NE: '<'
         }
      }
   },

   components: {
      condition,
      Cropper
   },

   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id && oldVal.id) {
               this.hotelId = this.hotelInfo.id
               this.formData.hotelId = this.hotelInfo.id
            }
         },
      }
   },
   computed: {
      ...mapState(['hotelInfo']),
      supportTypeSelect() {
         return this.supportType.filter(item => item.contentType == this.formData.contentType)
      },
       // 图片裁剪尺寸
      filmSize() {
        if (this.activeName === 'first') {
          return {
            autoCropWidth: 400,
            autoCropHeight: 300,
            fixedNumber: [4, 3],
            mode: '400px 300px',
          }
        } else {
          return {
            autoCropWidth: 400,
            autoCropHeight: 200,
            fixedNumber: [2, 1],
            mode: '400px 200px',
          }
        }
      },
   },

   filters: {
     
   },
   created() {
      this.pagetype = this.$route.query.action
        if(this.pagetype == 'look' || this.pagetype == 'edit'){
         this.formData = JSON.parse(sessionStorage.getItem('tempInfo'))
         if (this.formData.status) {
            this.$set(this.formData, 'status', this.formData.status)
         } else {
            this.$set(this.formData, 'status', 'NO')
         }
      } 
      if (this.pagetype == 'look') {
        this.disabled = true
      }
   },
   mounted() {
      this.formData.hotelId = this.hotelInfo.id
      this.getTagList()
   },

   methods: {

      // 获取标签列表
      getTagList() {
         getTagList({name: ''},10,1).then(res => {
           if (res.success) {
             this.tagData = res.records
             for (let i in this.tagData) {
               this.$set(this.tagData[i], 'checked', false)
             }
             if (this.pagetype == 'edit') {
               let tags = this.formData.tagId.split(',')
               for (let i in tags) {
                  for (let j in this.tagData) {
                     if (this.tagData[j].id == tags[i]) {
                        this.tagData[j].checked = true
                     }
                  }
               }
             }
           }
         })
      },

      //提交
      submit() {
         if (this.formData.name == "") {
            this.$message.error('请输入模板名称')
            return
         }
         if (!this.formData.tagId) {
            this.$message.error('请选择标签')
            return
         }
         if (this.formData.propStr == "" && this.formData.touchStr == '') {
            this.$message.error('请设置条件')
            return
         }

         if (this.formData.contentType != 'IMAGE') {
            if (this.formData.content == '') {
               this.$message.error('请输入模板内容')
               return
            }
         }

         if (this.formData.contentType != 'TEXT') {
            if (this.formData.image == '') {
               this.$message.error('请上传图片')
               return
            }
         }

         if (this.formData.description == '') {
            this.$message.error('请填写模板说明')
            return
         }

         this.formData.hotelId = this.hotelInfo.id
         this.formData.supportTypes = JSON.stringify(this.supportType.filter(item => item.contentType == this.formData.contentType)[0].content)


         // let param = this.formData
         saveTemp(this.formData).then((res) => {
            if (res.success) {
               this.$message({
                  message: "保存成功!",
                  type: "success",
               });
               this.$router.push("/marketing_template");
            }
         })

      },

      dataConversion(data) {
         let str = ''
         data = JSON.parse(data)
         for (let item of data) {
            switch (item.field) {
               case 'gender': str += '性别=' + this.gender[item.value] + '<br>';break;
               case 'age': str += '年龄=' + item.value + '<br>';break;
               case 'address': str += '地址=' + item.value + '<br>';break;
               case 'money': str += '销售额' + this.operator[item.operator] + item.value + '<br>';break;
               case 'times': str += '订单数量' + this.operator[item.operator] + item.value + '<br>';break;
            }
         }
         return str
      },

       // 条件参数
      condition(propStr, touchStr) {
         this.formData.propStr = ''
         this.formData.touchStr = ''
         this.formData.propStrName = ''
         this.formData.touchStrName = ''
         if (propStr && propStr != 'undefined') {
            this.formData.propStr = propStr
            this.formData.propStrName = this.dataConversion(propStr)
         }
         if (touchStr && touchStr != 'undefined') {
            this.formData.touchStr = touchStr
            this.formData.touchStrName = this.dataConversion(touchStr)
         }
         this.visibleCondition = false
      },

      // 标签选中
      tagChecked() {
         this.dialogTagVisible = false
         this.formData.tagId = ''
         for (let i in this.tagData) {
            if (this.tagData[i].checked) {
               if (!this.formData.tagId) {
                  this.formData.tagId += this.tagData[i].id
               } else {
                  this.formData.tagId += ',' + this.tagData[i].id
               }
            }
         }
      },

      // 关闭上传图片会话框
      closeUploadImg() {
        this.visibleImage = false
      },
      // 获取上传图片信息
      getImageInfo({ url }) {
        this.visibleImage = false
        this.$set(this.formData, 'image', url)
      },
      // 关闭弹窗时触发
      closeDialog() {
        this.visibleImage = false
      },

   },
}
</script>

<style scoped lang="scss">

.radio-box {
   ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      background: linear-gradient(90deg, #006FFF, #189AFF) !important;
   }
}

.pre-sale-main {
   padding: 20px;
   background: #fff;

   .right-form > .el-row {
      margin-bottom: 10px
   }

   .right-form {
      padding: 0 0 0 40px;
      width: 100%;

      .tit {
         font-size: 18px;
         margin-bottom: 30px
      }

      .input-box {
         line-height: 28px;
         overflow: hidden;

         .name {
            width: 100px;
            text-align: right;
            float: left;

            i {
               color: #ff0000;
               font-size: 18px;
               margin-right: 4px
            }
         }

         .flex {
            display: flex;
            display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
            display: -moz-box; /* Firefox 17- */
            display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
            display: -moz-flex; /* Firefox 18+ */
            display: -ms-flexbox; /* IE 10 */
            display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
         }

         .content {
            float: left;
            margin-left: 15px;
         }

         .radio {
            padding: 7px 0
         }

         .m-left-right {
            margin: 0 10px
         }

         .img-list {
            margin-top: 10px;

            .el-row {
               margin: 10px 10px 0 0
            }
         }

         /* 标签 */
         .el-tag + .el-tag {
            margin-left: 10px;
         }

         .button-new-tag {
            margin-left: 10px;
            height: 32px;
            line-height: 30px;
            padding-top: 0;
            padding-bottom: 0;
         }

         .input-new-tag {
            width: 90px;
            margin-left: 10px;
            vertical-align: bottom;
         }
      }
   }
}

.table-box {
   overflow: hidden;
   text-overflow: ellipsis;
   -webkit-line-clamp: 1;
   line-clamp: 1;
   -webkit-box-orient: vertical;
}

.tips {
   opacity: 0.5;
}

.img-list img {
   width: 100px;
   height: 100px;
}

.textinpt {
   min-width: 70px;
   width: 90px;
   border: 1px solid #dedede;
   padding: 3px 0;
   -webkit-border-radius: 3px; //适配以webkit为核心的浏览器(chrome、safari等)
   -moz-border-radius: 3px; //适配firefox浏览器
   -ms-border-radius: 3px; //适配IE浏览器
   -o-border-radius: 3px; //适配opera浏览器
   border-radius: 3px;
}

.textinpt:focus {
   outline: 1px solid #51c9ff;
   background: rgba(3, 16, 28, 0);
   padding: 1px;
   -webkit-border-radius: 3px; //适配以webkit为核心的浏览器(chrome、safari等)
   -moz-border-radius: 3px; //适配firefox浏览器
   -ms-border-radius: 3px; //适配IE浏览器
   -o-border-radius: 3px; //适配opera浏览器
   border-radius: 3px;
}







.type-text {
   padding-right: 30px;
}
</style>
